/*
Template Name: liqi - System
Author: Gimmer
Contact: support@gimmer.net
File: Main Css File
*/

/* TABLES OF CSS

1. General
2. Topbar
3. Page Header
4. Footer
5. Right Sidebar
6. Vertical
7. Horizontal Nav
8. Layouts
9. Waves
10. Avatar
11. Helper
12. Demos
13. Plugins
14. Authentication
15. Email
16. Coming Soon
17. Timeline
18. Extra Pages

*/

//Fonts
@import "custom/fonts/fonts";

//Core files
@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";
@import "variables";
@import "./node_modules/bootstrap/scss/mixins.scss";

// Structure
@import "custom/structure/general";
@import "custom/structure/topbar";
@import "custom/structure/page-head";
@import "custom/structure/footer";
@import "custom/structure/right-sidebar";
@import "custom/structure/vertical";
@import "custom/structure/horizontal-nav";
@import "custom/structure/layouts";

// Components
@import "custom/components/waves";
@import "custom/components/avatar";
@import "custom/components/accordion";
@import "custom/components/helper";
@import "custom/components/preloader";
@import "custom/components/forms";
@import "custom/components/widgets";
@import "custom/components/demos";
@import "custom/components/print";

// Plugins
@import "custom/plugins/custom-scrollbar";
@import "custom/plugins/calendar";
@import "custom/plugins/session-timeout";
@import "custom/plugins/round-slider";
@import "custom/plugins/range-slider";
@import "custom/plugins/sweatalert2";
@import "custom/plugins/rating";
@import "custom/plugins/toastr";
@import "custom/plugins/parsley";
@import "custom/plugins/select2";
@import "custom/plugins/switch";
@import "custom/plugins/datepicker";
@import "custom/plugins/datatable";
@import "custom/plugins/form-editors";
@import "custom/plugins/form-upload";
@import "custom/plugins/form-wizard";
@import "custom/plugins/responsive-table";
@import "custom/plugins/table-editable";
@import "custom/plugins/apexcharts";
@import "custom/plugins/flot";
@import "custom/plugins/sparkline-chart";
@import "custom/plugins/google-map";
@import "custom/plugins/vector-maps";

// Pages
@import "custom/pages/authentication";
@import "custom/pages/ecommerce";
@import "custom/pages/email";
@import "custom/pages/chat";
@import "custom/pages/kanbanboard";
@import "custom/pages/coming-soon";
@import "custom/pages/timeline";
@import "custom/pages/extras-pages";

// liqi

:root {
  --black: #0f0f0f;
  --yellow: #ffd400;
  --white: #ffffff;
  --pinkishGrey: #bcbcbc;
  --lightGrey: #e9eae8;
  --danger: #e33a58;
  --warmGrey: #777777;
  --snow: #f0f0f0;
  --greyishBrown: #555555;
  --grey: #cecece;
  --greyish-: #4e4e4e;
  --orangeYellow: #ffa900;
  --tangerine: #ff8b00;
  --pinkish-grey: #d0d0d0;
  --warm-grey: #9e9e9e;
  --greyishBrownTwo: #3b3836;
  --purplishGrey: #5f5d60;
  --silver: #cdd6db;
  --whiteTwo: #fbfbfb;
  --lipstick: #e53755;
  --warmGreyThree: #808080;
  --blue: #0068ff;
  --darkBlue: #0034bb;
}

.navbar-header,
.navbar-brand-box,
body[data-sidebar="dark"] .navbar-brand-box,
body[data-sidebar="dark"] .vertical-menu {
  background: var(--black);
}

button#vertical-menu-btn {
  @media (min-width: 992px) {
    display: none;
  }
}

// Novo Item

button.ant-btn.ant-btn-dashed.aligner-item {
  background: var(--blue);
  border: none;
  border-radius: 3px;
  color: var(--white);
  font-weight: bold;
  &:hover {
    background: var(--darkBlue);
    color: var(--white);
  }
}

// Tabs

.ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
  background: var(--blue);
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--blue);
  font-weight: bold;
}

.liqi-icon-color,
.mr-3.text-primary,
.mdi.mdi-trash-can.font-size-18 {
  color: var(--greyish-) !important;

  > svg {
    color: var(--greyish-);
    &:hover {
      color: var(--blue);
    }
  }

  &:hover {
    color: var(--blue);
  }
}

.liqi-bg-btn {
  background: var(--black);
}

.ant-btn.ant-btn.ant-btn-primary {
  background: var(--blue);
  border: none;
  font-weight: bold;
  color: var(--white);
  border-radius: 3px;
  &:hover {
    background: var(--darkBlue);
    color: var(--white);
  }
}

// Right Sidebar !ONLY DEV!

.right-bar,
.rightbar-overlay {
  display: none !important;
}
