.aligner {
    display: flex;
    align-items: center;
    justify-content: center;
}

.aligner-item {
    flex: 1;
}

.aligner-item--top {
    align-self: flex-start;
}

.aligner-item--bottom {
    align-self: flex-end;
}

.aligner-item--fixed {
    flex: none;
    max-width: 50%;
}

.ant-form-item-explain
{
    min-height: 32px;
}

.MuiAutocomplete-inputRoot {
    padding: 0px !important;
    padding-right: 200px;
}

.MuiAutocomplete-input{
    padding-top: 13px !important;
    font-size: 13px !important;
}