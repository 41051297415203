//Import Icon scss
@import './assets/scss/icons.scss';

//import Light Theme
@import './assets/scss/bootstrap.scss';
@import './assets/scss/app.scss';
@import 'toastr';

//Import Dark theme
// @import "./assets/scss/bootstrap-dark.scss";
// @import "./assets/scss/app-dark.scss";

// RTL mode
// @import "./assets/scss/bootstrap.scss";
// @import "./assets/scss/app-rtl.scss";

.accordian-bg-products {
	background-color: $gray-300;
}

.nav-link {
	cursor: pointer;
}

.dataTables_filter,
.dataTables_paginate {
	float: right;
	.pagination {
		.page-link {
			border-radius: 30px !important;
			margin: 0 3px;
			border: none;
			text-align: center;
			cursor: pointer;
		}
	}
}

.rdw-editor-main {
	border: 1px solid $gray-300;
	height: 239px;
}
.dz-message {
	text-align: center;
	padding: 100px;
}

.react-datepicker-wrapper {
	width: 100% !important;
}

.ReactModal__Overlay {
	z-index: 1001 !important;
}

.rangeslider__fill {
	background-color: $primary !important;
}

.react-bootstrap-table {
	padding-top: 0px !important;
}

.square-switch {
	.react-switch-bg {
		border-radius: 5px !important;
	}
}

input[type='range'] {
	-webkit-appearance: auto !important;
}

.chat-conversation-height {
	min-height: 500px;
}

.expand-cell {
	cursor: pointer !important;
}

.sorting_desc,
.sorting_asc {
	position: relative !important;
}

.sorting_asc::before,
.sorting_desc::after {
	position: absolute !important;
	left: auto !important;
	right: 5px !important;
	display: inline;
}

.sorting_desc::before {
	display: none !important;
}

.sorting_asc::after {
	display: none !important;
}

// .toast-success {
//     border: 2px solid #1cbb8c !important;
//     background-color: rgba(28, 187, 140, 0.8) !important;
//     position: fixed;
//     right: 50px;
//     top: 120px;
//     z-index: 99999;
//     padding: 20px;
//     color: white;
// }
